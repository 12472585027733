'use strict';

window.onload = function() {document.body.scrollTop = document.documentElement.scrollTop = 0;};


var body = document.body,
    timer;

window.addEventListener('scroll', function() {
    clearTimeout(timer);
    if(!body.classList.contains('disable-hover')) {
        body.classList.add('disable-hover');
    }

    timer = setTimeout(function(){
        body.classList.remove('disable-hover');
    },500);
}, false);


// Javascript classes ==================================================================================================
let resonsiveBackgroundImages;
class ResponsiveBackgroundImage {
    constructor(ele) {
        this.ele = ele;
        this.img = ele.querySelector('img');
        this.img = ele.querySelectorAll('[data-resbgimg]');
        this.src = '';

        if (this.img.length > 0) {
            this.img = this.img[0];

            this.img.addEventListener('load', () => {
                this.update();
            });

            if (this.img.complete) {
                this.update();
            }
        }
    }

    update() {
        let src = typeof this.img.currentSrc !== 'undefined' ? this.img.currentSrc : this.img.src;
        if (this.src !== src) {
            this.src = src;
            this.ele.style.backgroundImage = 'url("' + this.src + '")';

        }
    }
}


// Javascript methods ==================================================================================================
if (!String.prototype.base64DecodeUnicode) {
    /**
     * String.base64EncodeUnicode()
     *
     * First we use encodeURIComponent to get percent-encoded UTF-8, then we convert the percent encodings into raw
     * bytes which can be fed into btoa.
     *
     * @returns {*}
     */
    String.prototype.base64EncodeUnicode = function () {
        return btoa(encodeURIComponent(this).replace(/%([0-9A-F]{2})/g,
            function toSolidBytes(match, p1) {
                return String.fromCharCode('0x' + p1);
            }));
    };
}

if (!String.prototype.base64DecodeUnicode) {
    /**
     * String.base64DecodeUnicode()
     *
     * Going backwards: from bytestream, to percent-encoding, to original string.
     *
     * @returns {*}
     */
    String.prototype.base64DecodeUnicode = function () {
        return decodeURIComponent(atob(this).split('').map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));
    };
}

if (!String.prototype.padEnd) {
    /**
     * String.padEnd()
     * @param targetLength
     * @param padString
     * @returns {*}
     */
    String.prototype.padEnd = function padEnd(targetLength, padString) {
        targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
        padString = String(typeof padString !== 'undefined' ? padString : ' ');
        if (this.length > targetLength) {
            return String(this);
        } else {
            targetLength = targetLength - this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return String(this) + padString.slice(0, targetLength);
        }
    };
}

if (!String.prototype.padStart) {
    /**
     * String.padStart()
     *
     * @param targetLength
     * @param padString
     * @returns {*}
     */
    String.prototype.padStart = function padStart(targetLength, padString) {
        targetLength = targetLength >> 0; //floor if number or convert non-number to 0;
        padString = String(typeof padString !== 'undefined' ? padString : ' ');
        if (this.length > targetLength) {
            return String(this);
        } else {
            targetLength = targetLength - this.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(this);
        }
    };
}

// Check if jQuery is defined
if (typeof jQuery !== 'undefined') {

    // No conflict for multiple use of use the $ sign
    var jQ1 = jQuery.noConflict();
    jQ1(function ($) {



        // region: Cookie functions

        function setClickedCookie() {
            setCookie('bd_cookie_clicked', '1', (1 * 365));
            window.location.reload();
        }

        function setAllCookies() {
            setCookie('bd_cookie_clicked', '1', (1 * 365));
            setCookie('bd_cookie_analytics', '1', (1 * 365));
            setCookie('bd_cookie_thirdparty', '1', (1 * 365));
            window.location.reload();
        }

        $(document).on('click', '[data-setclickedcookie]', function() {
            setClickedCookie();
        });

        $(document).on('click', '[data-setallcookies]', function() {
            setAllCookies();
        });

        if (!getCookie('bd_cookie_clicked')) {
            $.fancybox.open({
                autoFocus: false,
                src: '#cookiebanner-hint-popup',
                afterLoad: function (e, obj) {
                    obj.$content.find('[tabindex="1"]').focus();
                }
            });
        }
        // endregion



        var sliderContentContainerMaxHeight = 0;
        var $slidesContentContainer = $('.slides-content-container');
        var $sliderContentContainer = $('.slide-content-container');



        function calculateSlideContainerTextHeight() {
            sliderContentContainerMaxHeight = 0;
            $sliderContentContainer.each(function (e) {
                var $this = $(this);
                // console.log($this.outerHeight());
                // console.log($this.outerHeight() > sliderContentContainerMaxHeight);
                sliderContentContainerMaxHeight = $this.outerHeight() > sliderContentContainerMaxHeight ? $this.outerHeight() : sliderContentContainerMaxHeight;
            }).promise().done(function (e) {
                // console.log(sliderContentContainerMaxHeight);
                $slidesContentContainer.height(sliderContentContainerMaxHeight);
            });
        }

        $(window).on('load', function (e) {
            calculateSlideContainerTextHeight();

        });
        $(window).on('resize', function (e) {
            calculateSlideContainerTextHeight();
        });



        var $slider = $('.navbar-header .slider');

        var navbarSlideLeaveTimeout = null;
        var navbarSlideLeaveLock = false;

        $('.navbar-header ul li').each(function(){
            var x = $(this).position().left,
                w = $(this).outerWidth();
            $(this).on({
                mouseenter: function(){
                    clearTimeout(navbarSlideLeaveTimeout);
                    if (navbarSlideLeaveLock) {
                        navbarSlideLeaveLock = false;
                    } else {

                        $slider.css({left: x, width: w});
                    }
                },
                mouseleave: function(){


                    // console.log('leave');
                    if (navbarSlideLeaveLock) {
                        clearTimeout(navbarSlideLeaveTimeout);
                        navbarSlideLeaveLock = false;
                    } else {

                        navbarSlideLeaveTimeout = setTimeout(function() {


                            // console.log('leave called');

                            var sliderObj = $('.navbar-header a.active');
                            var $slider = $('.navbar-header .slider');


                            if (sliderObj.length == 0) {
                                $slider.css({width: 0});
                            } else {
                                var isX = sliderObj.position().left,
                                    isW = sliderObj.outerWidth();
                                $slider.css({ left: isX, width: isW });
                            }
                        }, 100);
                    }


                }
            });
        });


        function navbarHeaderSlider(activeObj) {
            var $slider = $('.navbar-header .slider'),
                width = $('.navbar-header ul li').width,
                $isActive = activeObj;

            if ($isActive.length > 0) {
                var isX = $isActive.position().left,
                    isW = $isActive.outerWidth();
            }
            $('.navbar-header ul li').each(function(){

                $slider.css({ left: isX, width: isW });
            });
        }


        $(window).on('activate.bs.scrollspy', function (e,obj) {
            var sliderObj = $('.navbar-header a.active');

            clearTimeout(navbarSlideLeaveTimeout);
            navbarHeaderSlider(sliderObj);
        });
        var scrollSliderTimeout = null;
        var scrollSliderTimeoutLock = false;

        $(window).on('scroll', function () {
            var sliderObj = $('.navbar-header a.active');
            var $slider = $('.navbar-header .slider');


            // console.log('window scrolled');
            clearTimeout(navbarSlideLeaveTimeout);

            if (sliderObj.length == 0) {
                if (!scrollSliderTimeoutLock){
                    scrollSliderTimeout = setTimeout(function () {

                        $slider.css({width: 0});
                        scrollSliderTimeoutLock = false;
                    }, 310);
                    scrollSliderTimeoutLock = true
                }
            } else {
                clearTimeout(scrollSliderTimeout);
                scrollSliderTimeoutLock = false;
            }
        });




        /*
                var scroll = function () {
                    // do the onscroll stuff you want here
                    // console.log('test');
                };
                var waiting = false, endScrollHandle;
                $(window).scroll(function () {
                    if (waiting) {
                        return;
                    }
                    waiting = true;

                    // clear previous scheduled endScrollHandle
                    clearTimeout(endScrollHandle);

                    scroll();

                    setTimeout(function () {
                        waiting = false;
                    }, 100);

                    // schedule an extra execution of scroll() after 200ms
                    // in case the scrolling stops in next 100ms
                    endScrollHandle = setTimeout(function () {
                        scroll();
                    }, 200);
                });


        */




        // init controller
        var controller = new ScrollMagic.Controller();

        // build tween
        var tween1 = TweenMax.to("#bannerPizza", .3, {scale: 0.5, repeat: 0, yoyo: false});
        var tween2 = TweenMax.to("#playButton", .3, {opacity: 0,display:"none"});
        //var tween4 = TweenMax.to("#bannerPizzaForSlides", .3, {opacity: 1, scale: 1});

        var tweenFixSectionSlider = TweenMax.to("#pizza-szene-1", .3, {position: 'fixed', top: 0});
        var tweenRelativeSectionSlider = TweenMax.to("#pizza-szene-1", .3, {position: 'relative', top: 'auto'});



        // Empty body scene needed for anchor
        var body = new ScrollMagic.Scene({ triggerElement: "#body",
            triggerHook: "onStart", duration: "100%" })
            .addTo(controller);

        // build scene and set duration to window height
        var scene1 = new ScrollMagic.Scene({ triggerElement: "#triggerBannerPizza",
            triggerHook: "onCenter", duration: "100%" })
            .setTween(tween1)
            .addTo(controller);

        var scene2 = new ScrollMagic.Scene({ triggerElement: "#start",
            triggerHook: "onLeave", duration: "75%" })
            .setTween(tween2)
            .addTo(controller);











        var sceneSetTmpHiddenNavbar = new ScrollMagic.Scene({
            triggerElement: "#start",  triggerHook: "onLeave", duration: "100%", reverse: true, offset: $('#start').outerHeight()
        })
            .setClassToggle(".navbar-header", "tmp-hidden") // add class toggle
            .addTo(controller);


        var sceneSetNavbarFixed = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-1", triggerHook: "onLeave", duration: 0, reverse: true
        })
            .setClassToggle(".navbar-header", "fixed-top") // add class toggle
            .addTo(controller);


        if ($('#pizza-szene-end').length) {
            var sceneTransformNavbarHeaderAfterSlider = new ScrollMagic.Scene({
                triggerElement: "#pizza-szene-end", triggerHook: "onLeave", duration: "100%", reverse: true
            })
                .setTween(
                    TweenMax.to(".navbar-header .slider-full", 1, {opacity: 1,height: 3, width: $('.navbar-header [href="#das-team"]').outerWidth(), left: $('.navbar-header [href="#das-team"]').position().left} )
                )
                .addTo(controller);
        }


        var sceneAfterTransformNavbarHeaderHideNormalSlider = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-end", triggerHook: "onLeave", duration: 0, reverse: true
        })
            .setTween(
                TweenMax.to(".navbar-header .slider", .15, {opacity: 0})
            )
            .addTo(controller);

        var sceneAfterTransformNavbarHeaderAfterSlider = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-end", triggerHook: "onLeave", duration: 0, reverse: true, offset: $('#pizza-szene-end').outerHeight()
        })
            .setTween(
                TweenMax.to(".navbar-header .slider-full", 1, {backgroundColor: '#bcbc80'})
            )
            .addTo(controller);






        /*
        var sceneRemoveTmpHiddenNavbar = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-1", triggerHook: "onLeave", duration: 0 })
            .setClassToggle(".navbar-header", "tmp-hidden") // add class toggle
            .addTo(controller);
        */



        // var scene4 = new ScrollMagic.Scene({ triggerElement: "#start5",
        //     triggerHook: "onLeave", duration: "85%" })
        //     .setTween(tween4)
        //     .addTo(controller);





        var slideEndHidePizza = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-end",
            triggerHook: "onLeave", duration: "100%" })
            .setTween(
                TweenMax.to("#pizza-slider-image-container", 1, {opacity: 0})
            )
            .addTo(controller);

        var slideEndHidePizzaScene = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-end",
             triggerHook: "onLeave", duration: "100%", offset: $('#pizza-szene-end').outerHeight() /2 })
             .setClassToggle("#pizza-slider-image-container", "remove-transition") // add class toggle
             .setTween(
                 new TweenMax.to("#pizza-szene-1", 1, {height: 0})
             )
             .addTo(controller);

        var slideEndHidePizzaScene1InnerBg = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-end",
            triggerHook: "onLeave", duration: "100%" })
            .setTween(
                TweenMax.to("#pizza-szene-1 .background-wrapper", 1, {height: 0})
            )
            .addTo(controller);


        var slideEndHidePizzaSceneText = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-end",
            triggerHook: "onLeave", duration: 0 })
            .setClassToggle("#pizza-szene-1 .text-container", "opacity-0") // add class toggle
            .addTo(controller);







        /*var slideEndHidePizzaSceneEnd = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-end",
            triggerHook: "onLeave", duration: "100%" })
            .setClassToggle("#pizza-slider-image-container", "remove-transition") // add class toggle
            .setTween(
                TweenMax.to("#pizza-szene-end", 1, {height: 0})
            )
            .addTo(controller);*/

        /*var scene5 = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-end",
            triggerHook: "onLeave", duration: "100%" })
            .setTween(tweenRelativeSectionSlider)
            .addTo(controller); */



        var sceneHidePizzaSceneBackground = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-1",
            triggerHook: "onLeave", duration: "600%",reverse: 1, offset: 1})
            .setTween(tweenFixSectionSlider)
            .addTo(controller);


        var sceneShowAnchorDots = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-1",
            triggerHook: "onLeave", duration: "500%",reverse: 1 })
            .setClassToggle(".bd-aside-anchor-nav", "show") // add class toggle
            .addTo(controller);


        /*var sceneHidePizzaSceneBackgroundLast = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-5",
            triggerHook: "onLeave", duration: "100%",reverse: 1 })
            .setTween(TweenMax.to("#pizza-szene-1", .3, {position: 'absolute', top: 0}))
            .addTo(controller);*/

        var sceneHidePizzaScene1 = new ScrollMagic
            .Scene({
                triggerElement: "#pizza-szene-1",
                triggerHook: "onLeave",
                duration: 0,
                offset: 1
            })
            .setClassToggle("#pizza-slider-image-container .pizza-container-inner", "rotate") // add class toggle
            .addTo(controller);

        var sceneHidePizzaScene1SplitSlices = new ScrollMagic
            .Scene({
                triggerElement: "#pizza-szene-1",
                triggerHook: "onLeave",
                duration: "500%",
                offset: 1
            })

            .setClassToggle("#pizza-slider-image-container", "split-slices") // add class toggle
            .addTo(controller);


        /*

                var sceneHidePizzaScene2SliceActive = new ScrollMagic
                    .Scene({ triggerElement: "#pizza-szene-2", triggerHook: "onLeave", duration: "100%", delay: 300 })
                    .setClassToggle(".pizza-slider-image-01", "active") // add class toggle
                    .addTo(controller);
        */
        var slideText01 = null,
            slideText02 = null,
            slideText03 = null,
            slideText04 = null,
            slideText05 = null;

        slideText01 = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-1",
            duration: "100%",
            triggerHook: 0,

            offset: 1,
        }).setClassToggle("#slide-01-content", "o-visible");

        slideText02 = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-2",
            duration: "100%",
            triggerHook: 0,

            offset: 1,
        }).setClassToggle("#slide-02-content", "o-visible");

        slideText03 = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-3",
            duration: "100%",
            triggerHook: 0,

            offset: 1,
        }).setClassToggle("#slide-03-content", "o-visible");

        slideText04 = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-4",
            duration: "100%",
            triggerHook: 0,

            offset: 1,
        }).setClassToggle("#slide-04-content", "o-visible");

        slideText05 = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-5",
            duration: "100%",
            triggerHook: 0,

            offset: 1,
        }).setClassToggle("#slide-05-content", "o-visible");

        controller.addScene([
            slideText01,
            slideText02,
            slideText03,
            slideText04,
            slideText05,
        ]);



        var
            slideHint02 = null,
            slideHint03 = null,
            slideHint04 = null,
            slideHint05 = null;

        slideHint02 = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-3",
            duration: "100%",
            triggerHook: 1,

            offset: 0,
        }).setClassToggle(".pizza-slider-popup-01", "o-visible");

        slideHint03 = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-4",
            duration: "100%",
            triggerHook: 1,

            offset: 0,
        }).setClassToggle(".pizza-slider-popup-02", "o-visible");

        slideHint04 = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-5",
            duration: "100%",
            triggerHook: 1,

            offset: 0,
        }).setClassToggle(".pizza-slider-popup-03", "o-visible");

        slideHint05 = new ScrollMagic.Scene({
            triggerElement: "#pizza-szene-end",
            duration: "100%",
            triggerHook: 1,

            offset: 0,
        }).setClassToggle(".pizza-slider-popup-04", "o-visible");

        controller.addScene([
            slideHint02,
            slideHint03,
            slideHint04,
            slideHint05,
        ]);



        /******************************
         * SLIDE 1
         */
        var slide01DeactivateSlices1 = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-2", triggerHook: "onLeave", duration: 0, reverse: true, offset: 1 })
            .setClassToggle(".pizza-slider-image", "deactive") // add class toggle
            ;

        var slide01ActivateSlices = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-2", triggerHook: "onLeave", duration: "100%", reverse: true, offset: 1})
            .setClassToggle(".pizza-slider-image-01", "active") // add class toggle
            ;


        controller.addScene﻿([
            slide01DeactivateSlices1,
            slide01ActivateSlices,

        ]);

        /******************************
         * SLIDE 2
         */
        var slide02ActivateSlices = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-3", triggerHook: "onLeave", duration: "100%", reverse: true, offset: 1})
            .setClassToggle(".pizza-slider-image-02", "active") // add class toggle

            ;

        controller.addScene﻿([
            slide02ActivateSlices

        ]);

        /******************************
         * SLIDE 3
         */
        var slide03ActivateSlices = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-4", triggerHook: "onLeave", duration: "100%", reverse: true, offset: 1 })
            .setClassToggle(".pizza-slider-image-03", "active") // add class toggle

            ;

        controller.addScene﻿([
            slide03ActivateSlices

        ]);

        /******************************
         * SLIDE 4
         */
        var slide04ActivateSlices = new ScrollMagic.Scene({ triggerElement: "#pizza-szene-5", triggerHook: "onLeave", duration: "100%",  reverse: true, offset: 1 })
            .setClassToggle(".pizza-slider-image-04", "active") // add class toggle

;
        controller.addScene﻿([
            slide04ActivateSlices

        ]);

        /******************************
         * anfrageformular
         */

        var showContactHintPizza = new ScrollMagic.Scene({ triggerElement: "#anfragen-hinweis", triggerHook: "onStart", duration: "100%", offset: ($('#anfragen-hinweis').outerHeight() * -1),  reverse: true })
            .setTween(new TimelineMax().to("#anfragen-hinweis .pizza-box-pizza-container", 1, { opacity: 1} ));

        controller.addScene﻿([
            showContactHintPizza
        ]);




        /*var sceneHidePizzaScene3SliceActive = new ScrollMagic
            .Scene({ triggerElement: "#pizza-szene-3", triggerHook: "onLeave", duration: 0, delay: 300 })
            .setClassToggle(".pizza-slider-image-01", "active") // add class toggle
            .addTo(controller);*/
        /*var sceneHidePizzaScene3SliceDeactivateOthers = new ScrollMagic
            .Scene({ triggerElement: "#pizza-szene-3", triggerHook: "onLeave", duration: 0, })
            .removeClassToggle(true)
            .setClassToggle(".pizza-slider-image", "deactive")
            .setClassToggle(".pizza-slider-image-02", "active")
            .addTo(controller);
        var sceneHidePizzaScene4SliceDeactivateOthers = new ScrollMagic
            .Scene({ triggerElement: "#pizza-szene-4", triggerHook: "onLeave", duration: 0, })
            .removeClassToggle(true)
            .setClassToggle(".pizza-slider-image", "deactive")
            .setClassToggle(".pizza-slider-image-03", "active")
            .addTo(controller);
        var sceneHidePizzaScene5SliceDeactivateOthers = new ScrollMagic
            .Scene({ triggerElement: "#pizza-szene-5", triggerHook: "onLeave", duration: 0, })
            .removeClassToggle(true)
            .setClassToggle(".pizza-slider-image", "deactive")
            .setClassToggle(".pizza-slider-image-04", "active")
            .addTo(controller);
*/

        var sceneHidePizzaScene4SliceActive = null;
        var sceneHidePizzaScene5SliceActive = null;

        /*var scene5 = new ScrollMagic.Scene({ttriggerElement: "#pizza-szene-1",
            triggerHook: "onEnter", duration: "200%" })
            .setTween(tweenFixSectionSlider)
            .addTo(controller);*/


        // SCENE 6 - pin the first section
        // and update text

        // var pinScene01Tl = new TimelineMax();
        //
        // pinScene01Tl
        //     .to($('#pizza-szene-1 h1'), 0.2, {autoAlpha: 0, ease:Power1.easeNone}, 1.5)
        //     .to($('#pizza-szene-1 section'), 0.2, {autoAlpha: 0, ease:Power1.easeNone}, 1.5)
        //     .fromTo($('#pizza-szene-1 h1'), 0.7, {y: '+=20'}, {y: 0, autoAlpha: 1, ease:Power1.easeOut}, '+=0.4')
        //     .fromTo($('#pizza-szene-1 section'), 0.6, {y: '+=20'}, {y: 0, autoAlpha: 1, ease:Power1.easeOut}, '-=0.6')
        //     .set($('#pizza-szene-1 h1'), {autoAlpha: 1}, '+=2');

        /* var pinScene01 = new ScrollMagic.Scene({
             triggerElement: '#pizza-szene-1',
             triggerHook: 0,
             duration: "250%",
             triggerHook: "onLeave",
         })
             .setPin("#pizza-szene-1")
             // .setTween(pinScene01Tl)
             .addTo(controller);


     */



        var slides = $(".c-scene:not(:last)");
        var durat = [];
        slides.each(function() {
            var h;
            if ($(this).height() < $(window).height()) {
                h = $(this).height();
            } else {
                h = 1;
            }
            durat.push(h);
        });
        slides.each(function(index) {

            new ScrollMagic.Scene({
                triggerElement: slides.get(index),
                // duration: slides.eq(index).height(),
                duration: durat[index],
                // offset: slides.eq(index - 1 * index).offset().top
                loglevel: 3, reverse: false, triggerHook: "onLeave"
            })
                .setPin(slides.get(index))
                .addTo(controller);
        });

        new ScrollMagic.Scene({
            triggerElement: $(".c-scene").last().get(0),
            // duration: slides.eq(index).height(),
            duration: "100%",
            // offset: slides.eq(index - 1 * index).offset().top
            loglevel: 3, reverse: false, triggerHook: "onLeave"
        })
            .setPin( $(".c-scene").last().get(0), {pushFollowers: false})
            .addTo(controller);

        $(window).on('beforeunload', function() {
            $(window).scrollTop(0);
        });
            $(document).ready(function(){
                $('html').animate({scrollTop:0}, 1);
                $('body').animate({scrollTop:0}, 1);
            });


        $(document).on("click", "a[href^='#']:not([data-sceneanchor]):not([data-fancybox])", function (e) {
            const scrollTop = $($(this).attr('href')).position().top;


            /*$('html,body').animate({
                scrollTop: scrollTop
            }, Math.abs(window.scrollY - scrollTop) * 0.25);*/

            $('html, body').scrollTop(scrollTop);
        });

        $(document).on("click", "a[href^='#'][data-sceneanchor]:not([data-fancybox])", function (e) {
            var id = $(this).attr("href");


            navbarSlideLeaveLock = true;

            clearTimeout(navbarSlideLeaveTimeout);
            if ($(id).length > 0) {
                e.preventDefault();

                // trigger scroll
                // controller.scrollTo(id);

                switch (id) {
                    case '#start': controller.scrollTo(scene1.scrollOffset()); break;
                    case '#body': controller.scrollTo(body.scrollOffset()); break;
                    case '#pizza-szene-1': controller.scrollTo(sceneHidePizzaScene1SplitSlices.scrollOffset() + 2); break;
                    case '#pizza-szene-2': controller.scrollTo(slide01ActivateSlices.scrollOffset() + 1); break;
                    case '#pizza-szene-3': controller.scrollTo(slide02ActivateSlices.scrollOffset() + 1); break;
                    case '#pizza-szene-4': controller.scrollTo(slide03ActivateSlices.scrollOffset() + 1); break;
                    case '#pizza-szene-5': controller.scrollTo(slide04ActivateSlices.scrollOffset() + 1); break;
                }





                // if supported by the browser we can even update the URL.
                /*if (window.history && window.history.pushState) {
                    history.pushState("", document.title, id);
                }*/
            }
        });




        /*
                controller.scrollTo(function (newpos) {
                    TweenMax.to(window, 0.5, {scrollTo: {y: newpos}});
                });*/
        /* controller.scrollTo(function(newScrollPos) {
            $("html, body").animate({
                scrollTop: newScrollPos
            });
        });*/

        //  bind scroll to anchor links







        var scroll = function () {
            // do the onscroll stuff you want here

        };
        var raf = window.requestAnimationFrame ||
            window.webkitRequestAnimationFrame ||
            window.mozRequestAnimationFrame ||
            window.msRequestAnimationFrame ||
            window.oRequestAnimationFrame;
        var $window = $(window);
        var lastScrollTop = $window.scrollTop();

        if (raf) {
            loop();
        }

        function loop() {
            var scrollTop = $window.scrollTop();
            if (lastScrollTop === scrollTop) {
                raf(loop);
                return;
            } else {
                lastScrollTop = scrollTop;

                // fire scroll function if scrolls vertically
                scroll();
                raf(loop);
            }
        }



        const $navbar = $('.navbar');


        /*
                $('a[href^="#"]').on('click', function(e) {
                    e.preventDefault();

                    // console.log($(this).attr('href'));
                    // const scrollTop = $($(this).attr('href')).position().top - $navbar.outerHeight();
                    const scrollTop = $($(this).attr('href')).position().top;


                    $('html,body').animate({
                        scrollTop: scrollTop
                    }, Math.abs(window.scrollY - scrollTop) * 0.25);

                    //$('html, body').animate({ scrollTop });
                })*/



        function setCookie(name,value,days) {
            var expires = "";
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days*24*60*60*1000));
                expires = "; expires=" + date.toUTCString();
            }
            document.cookie = name + "=" + (value || "")  + expires + "; path=/";
        }
        function getCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for(var i=0;i < ca.length;i++) {
                var c = ca[i];
                while (c.charAt(0)==' ') c = c.substring(1,c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
            }
            return null;
        }
        function eraseCookie(name) {
            document.cookie = name+'=; Max-Age=-99999999;';
        }


        $.fancybox.defaults.closeExisting = true;
        $.fancybox.defaults.iframe.preload = true;


        $.fancybox.defaults.media = {
            youtube : {
                params : {
                    autoplay : 1
                }
            },
            vimeo : {
                params : {
                    autoplay : 1
                }
            }
        };
/*

        $().fancybox({
            selector  : '[data-fancybox]',
            afterLoad: function( instance, slide ) {

                // Tip: Each event passes useful information within the event object:

                // Object containing references to interface elements
                // (background, buttons, caption, etc)
                // console.info( instance.$refs );

                // Current slide options
                // console.info( slide.opts );

                // Clicked element
                // console.info( slide.opts.$orig );

                // Reference to DOM element of the slide
                // console.log( slide.$slide );
                slide.$slide.find('.fancybox-content').addClass('arsch').css({
                    height: 600,
                    width: 337
                });


            height: "600px",
                    width: "337px"

            }
        });*/

        $().fancybox({
            selector : '.vimeo-video-popup-trigger[data-horizontal]',
            baseClass: 'fancy-horizontal',

        });
        $().fancybox({
            selector : '.vimeo-video-popup-trigger-no-cookie[data-horizontal]',
            baseClass: 'fancy-horizontal',

        });

        $(document).on('click', '.vimeo-video-popup-trigger:not([data-fancybox])', function() {
            let $this = $(this),
                videoUrl = $(this).data('videourl'),
                isThirdPartyCookieAccepted = !!getCookie('bd_cookie_thirdparty');

            if (isThirdPartyCookieAccepted) {

                if ($this.hasAttr('data-horizontal')) {
                    $.fancybox.open({
                        src  : videoUrl,
                        type : 'iframe',
                        opts : {
                            vimeo : {
                                color : 'bcbc80'
                            }
                        },
                        beforeShow: function( instance, slide ) {
                            // console.log(slide);
                        }
                    }, {
                        baseClass : 'myCustomClass',
                    });
                } else {
                    $.fancybox.open({
                        src  : videoUrl,
                        type : 'iframe',
                        opts : {
                            vimeo : {
                                color : 'bcbc80'
                            }
                        },
                        beforeShow: function( instance, slide ) {
                            // console.log(slide);
                        }
                    });
                }

            } else {
                $.fancybox.open({
                    src  : '#popup-vimeo-cookie-hint',
                    afterShow: function( instance, slide ) {

                        instance.$refs.inner.find('[data-fancybox]').attr('href', videoUrl);
                        if (typeof $this.data('horizontal') !== 'undefined') {
                            instance.$refs.inner.find('[data-fancybox]')
                                .attr('data-horizontal', "tmp")
                                .addClass('vimeo-video-popup-trigger-no-cookie');
                        } else {
                            instance.$refs.inner.find('[data-fancybox]')
                                .removeAttr('data-horizontal')
                                .removeClass('vimeo-video-popup-trigger-no-cookie');
                        }
                    }
                });
            }
        });


        $(function() {
            var isThirdPartyCookieAccepted = !!getCookie('bd_cookie_thirdparty');


            if (isThirdPartyCookieAccepted) {
                $('.vimeo-video-popup-trigger').each(function() {
                    var $this = $(this);
                   $this.attr('data-fancybox', '');
                   $this.attr('data-src', $this.data('videourl'));
                });
            }

        });


        $('[data-fancybox="anfrageformular"]').fancybox({
        });





        // JQuery methods ==============================================================================================
        $.fn.isInViewport = function () {
            var elementTop = $(this).offset().top;
            var elementBottom = elementTop + $(this).outerHeight();
            var viewportTop = $(window).scrollTop();
            var viewportBottom = viewportTop + $(window).height();
            return elementBottom > viewportTop && elementTop < viewportBottom;
        };

        // Functions ===================================================================================================
        if (typeof initializeResponsiveBackgroundImages !== 'function') {
            window.initializeResponsiveBackgroundImages = function () {
                let resonsiveBackgroundImages = document.querySelectorAll('[data-resbgimgcontainer]');
                for (let i=0; i<resonsiveBackgroundImages.length; i++) {
                    new ResponsiveBackgroundImage(resonsiveBackgroundImages[i]);
                }
            };
        }

        if (typeof getPercentageScrollPosition !== 'function') {
            window.getPercentageScrollPosition = function () {

                var s = $(window).scrollTop(),
                    d = $(document).height(),
                    c = $(window).height();

                var scrollPercent = (s / (d - c)) * 100;

                return Math.ceil(scrollPercent);
            };
        }


        if (typeof percentageCirclePagePosition !== 'function') {
            window.percentageCirclePagePosition = function () {

                var $round = $('.round'),
                    roundRadius = $round.find('circle').attr('r'),
                    currentPercentageScrollPosition = getPercentageScrollPosition(),
                    roundPercent =  currentPercentageScrollPosition ? currentPercentageScrollPosition : 0,
                    roundCircum = 2 * roundRadius * Math.PI,
                    roundDraw = roundPercent * roundCircum / 100
                $round.css('stroke-dasharray', roundDraw  + ' 999');

            };
        }

        // OwlCarousel =================================================================================================
        if (typeof initializeOwlCarousels !== 'function') {
            window.initializeOwlCarousels = function () {
                if (jQuery().owlCarousel) {

                    var owlCustomerReviews = $('.owl-carousel-customerreviews');

                    owlCustomerReviews.each(function(key, value) {
                        $(this).owlCarousel({
                            items: 1,
                            loop: false,
                            center: true,
                            URLhashListener: true,
                            nav: true,
                            navText: ['', ''],
                            dots: false,
                            autoHeight: true,
                        });
                    });

                } else {
                    console.warn('Couldn\'t load jQuery-Plugin "owlCarousel".');
                }
            };
        }

        if (typeof initializePage !== 'function') {
            window.initializePage = function () {
                initializeResponsiveBackgroundImages();
                initializeOwlCarousels();
                percentageCirclePagePosition();
            };
        }

        // Initialize stuff ============================================================================================
        initializePage();

        // Windows scroll ==============================================================================================
        $(window).on('scroll', function () {
            percentageCirclePagePosition();
        });
    });
} else {
    console.warn('Couldn\'t load jQuery.');
}


